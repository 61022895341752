export type SUGGESTION = {
  id: string;
  name: string;
  label: string;
  shortName: string;
  code?: string;
  objectType?: string;
  url?: string;
};

export const SEARCH_COUNT_PER_PAGE = 50;
export const SEARCH_COUNT_PER_PAGE_V2 = 10;
export const ENTIRE_FRANCE_DISTANCE_MAX = 2000;
export const TLC_CARD_REFETCH_INTERVAL = 30 * 1000; // 30s

export const ANALYTICS_ADDRESS_TYPES = {
  SUGGESTED: 'SUGGESTED',
  LOCATION: 'LOCATION',
  ADDRESS: 'ADDRESS',
};

export const DEFAULT_ADDRESSES = [
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '75000',
    shortName: 'PARIS',
    label: 'Paris',
    fullAddress: 'Paris',
    id: '75000-PARIS',
    rawData: {
      properties: {
        name: 'Paris',
        postcode: '75000',
        city: 'Paris',
        type: 'municipality',
        label: 'Paris',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '31000',
    shortName: 'TOULOUSE',
    label: 'Toulouse',
    fullAddress: 'Toulous',
    id: '31000-TOULOUSE',
    rawData: {
      properties: {
        name: 'Toulouse',
        postcode: '31000',
        city: 'Toulouse',
        type: 'municipality',
        label: 'Toulouse',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '13000',
    shortName: 'MARSEILLE',
    label: 'Marseille',
    fullAddress: 'Marseille',
    id: '13000-MARSEILLE',
    rawData: {
      properties: {
        name: 'Marseille',
        postcode: '13000',
        city: 'Marseille',
        type: 'municipality',
        label: 'Marseille',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '69000',
    shortName: 'LYON',
    label: 'Lyon',
    fullAddress: 'Lyon',
    id: '69000-Lyon',
    rawData: {
      properties: {
        name: 'Lyon',
        postcode: '69000',
        city: 'Lyon',
        type: 'municipality',
        label: 'Lyon',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '44000',
    shortName: 'NANTES',
    label: 'Nantes',
    fullAddress: 'Nantes',
    id: '44000-Nantes',
    rawData: {
      properties: {
        name: 'Nantes',
        postcode: '44000',
        city: 'Nantes',
        type: 'municipality',
        label: 'Nantes',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '45000',
    shortName: 'ORLEANS',
    label: 'Orléans',
    fullAddress: 'Orléans',
    id: '45000-Orléans',
    rawData: {
      properties: {
        name: 'Orléans',
        postcode: '45000',
        city: 'Orléans',
        type: 'municipality',
        label: 'Orléans',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '51100',
    shortName: 'REIMS',
    label: 'Reims',
    fullAddress: 'Reims',
    id: '51100-Reims',
    rawData: {
      properties: {
        name: 'Reims',
        postcode: '51100',
        city: 'Reims',
        type: 'municipality',
        label: 'Reims',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '59000',
    shortName: 'LILLE',
    label: 'Lille',
    fullAddress: 'Lille',
    id: '59000-Lille',
    rawData: {
      properties: {
        name: 'Lille',
        postcode: '59000',
        city: 'Lille',
        type: 'municipality',
        label: 'Lille',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '33000',
    shortName: 'BORDEAUX',
    label: 'Bordeaux',
    fullAddress: 'Bordeaux',
    id: '33000-Bordeaux',
    rawData: {
      properties: {
        name: 'Bordeaux',
        postcode: '33000',
        city: 'Bordeaux',
        type: 'municipality',
        label: 'Bordeaux',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '76600',
    shortName: 'LE HAVRE',
    label: 'Le Havre',
    fullAddress: 'Le Havre',
    id: '76600-Le Havre',
    rawData: {
      properties: {
        name: 'Le Havre',
        postcode: '76600',
        city: 'Le Havre',
        type: 'municipality',
        label: 'Le Havre',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '67000',
    shortName: 'STRASBOURG',
    label: 'Strasbourg',
    fullAddress: 'Strasbourg',
    id: '67000-Strasbourg',
    rawData: {
      properties: {
        name: 'Strasbourg',
        postcode: '67000',
        city: 'Strasbourg',
        type: 'municipality',
        label: 'Strasbourg',
      },
    },
  },
  {
    type: 'municipality',
    analyticsType: ANALYTICS_ADDRESS_TYPES.SUGGESTED,
    zipCode: '34000',
    shortName: 'MONTPELLIER',
    label: 'Montpellier',
    fullAddress: 'Montpellier',
    id: '34000-Montpellier',
    rawData: {
      properties: {
        name: 'Montpellier',
        postcode: '34000',
        city: 'Montpellier',
        type: 'municipality',
        label: 'Montpellier',
      },
    },
  },
];

export const DEFAULT_SUGGESTIONS: SUGGESTION[] = [
  {
    id: 'suggestion_tlc',
    name: 'TLC',
    label: 'teleconsult-now', // A key to be translated
    shortName: 'suggestion_tlc',
    url: '/teleconsultation/sans-rdv/recherche/',
  },
  {
    id: 'suggestion_sm54',
    name: 'Médecin généraliste',
    code: 'SM54',
    shortName: 'medecin-generaliste',
    objectType: 'speciality',
    label: 'Médecin généraliste',
  },
  {
    id: 'suggestion_70',
    name: 'Masseur-kinésithérapeute',
    code: '70',
    shortName: 'masseur-kinesitherapeute',
    objectType: 'speciality',
    label: 'Masseur-kinésithérapeute',
  },
  {
    id: 'suggestion_80',
    name: 'Pédicure-podologue',
    code: '80',
    shortName: 'pedicure-podologue',
    objectType: 'speciality',
    label: 'Pédicure-podologue',
  },
  {
    id: 'suggestion_sm15',
    name: 'Dermatologue et vénérologue',
    code: 'SM15',
    shortName: 'dermatologue-et-venerologue',
    objectType: 'speciality',
    label: 'Dermatologue et vénérologue',
  },
  {
    id: 'suggestion_pharmacie',
    name: 'Pharmacie',
    code: '',
    shortName: 'pharmacie',
    objectType: 'speciality',
    label: 'Pharmacie',
  },
  {
    id: 'suggestion_sm04',
    name: 'Cardiologue',
    code: 'SM04',
    shortName: 'cardiologue',
    objectType: 'speciality',
    label: 'Cardiologue',
  },
  {
    id: 'suggestion_50',
    name: 'Sage-femme',
    code: '50',
    shortName: 'sage-femme',
    objectType: 'speciality',
    label: 'Sage-femme',
  },
  {
    id: 'suggestion_sm42',
    name: 'Psychiatre',
    code: 'SM42',
    shortName: 'psychiatre',
    objectType: 'speciality',
    label: 'Psychiatre',
  },
  {
    id: 'suggestion_sm19',
    name: 'Gynécologue médical',
    code: 'SM19',
    shortName: 'gynecologue-medical',
    objectType: 'speciality',
    label: 'Gynécologue médical',
  },
  {
    id: 'suggestion_71',
    name: 'Ostéopathe',
    code: '71',
    shortName: 'osteopathe',
    objectType: 'speciality',
    label: 'Ostéopathe',
  },
  {
    id: 'suggestion_sm48',
    name: 'Rhumatologue',
    code: 'SM48',
    shortName: 'rhumatologue',
    objectType: 'speciality',
    label: 'Rhumatologue',
  },

  {
    id: 'suggestion_40',
    name: 'Chirurgien dentiste',
    code: '40',
    shortName: 'chirurgien-dentiste',
    objectType: 'speciality',
    label: 'Chirurgien dentiste',
  },
  {
    id: 'suggestion_sm24',
    name: 'Gastro-entérologue et hépatologue',
    code: 'SM24',
    shortName: 'gastro-enterologue-et-hepatologue',
    objectType: 'speciality',
    label: 'Gastro-entérologue et hépatologue',
  },
  {
    id: 'suggestion_92',
    name: 'Orthoptiste',
    code: '92',
    shortName: 'orthoptiste',
    objectType: 'speciality',
    label: 'Orthoptiste',
  },
  {
    id: 'suggestion_sm40',
    name: 'Pédiatre',
    code: 'SM40',
    shortName: 'pediatre',
    objectType: 'speciality',
    label: 'Pédiatre',
  },
  {
    id: 'suggestion_vac01',
    name: 'Centre de vaccination',
    code: 'VAC01',
    shortName: 'centre-de-vaccination',
    objectType: 'speciality',
    label: 'Centre de vaccination',
  },
  {
    id: 'suggestion_sm38',
    name: 'Ophtalmologue',
    code: 'SM38',
    shortName: 'ophtalmologue',
    objectType: 'speciality',
    label: 'Ophtalmologue',
  },
  {
    id: 'suggestion_SM39',
    name: 'Oto-Rhino-Laryngologiste',
    code: 'SM39',
    shortName: 'oto-rhino-laryngologiste',
    objectType: 'speciality',
    label: 'Oto-Rhino-Laryngologiste',
  },
  {
    id: 'suggestion_sm57',
    name: 'Allergologue',
    code: 'SM57',
    shortName: 'allergologue',
    objectType: 'speciality',
    label: 'Allergologue',
  },
  {
    id: 'suggestion_sm32',
    name: 'neurologue',
    code: 'SM32',
    shortName: 'neurologue',
    objectType: 'speciality',
    label: 'neurologue ',
  },
  {
    id: 'suggestion_93',
    name: 'psychologue',
    code: '93',
    shortName: 'psychologue',
    objectType: 'speciality',
    label: 'psychologue',
  },
  {
    id: 'suggestion_60',
    name: 'infirmier',
    code: '60',
    shortName: 'infirmier',
    objectType: 'speciality',
    label: 'infirmier',
  },
];

export const DEFAULT_TIMEZONE = 'Europe/Paris';
export const SPECIALITY_VACCINATION_CODE = 'VAC01';
export const NO_PRAT_PREFER = 'NO_PRAT_PREFER';
export const DEFAULT_APPOINTMENT_CANCEL_DELAY_LIMITATION = 240; // in minutes
export const NO_PRAT_ONLINE = 'NO_PRAT_ONLINE';

export const MAIIA_NEWS_WP_SEO_CATEGORY_ID =
  process.env.MAIIA_NEWS_WP_SEO_CATEGORY_ID || 16;
export const MAIIA_NEWS_WP_CACHE_TTL =
  process.env.MAIIA_NEWS_WP_CACHE_TTL || 60;

export const KEY_LANG = 'maiia_lang';

export const SUPPORT_PHONE_NUMBER = '01 49 09 34 98';
export const SUPPORT_PHONE_NUMBER_HREF = SUPPORT_PHONE_NUMBER.replace(
  /\s/g,
  '',
);

// from backend AllowedSpeciality.java
export const ALLOWED_SPECIALITIES_SHORTNAMES = [
  'neuropsychiatre',
  'psychiatre-de-lenfant-et-de-ladolescent',
  'psychiatre',
  'psychologue',
  'cardiologue',
  'rhumatologue',
  'dieteticien',
  'orthoptiste',
  'chirurgien-dentiste',
  'gynecologue-obstetricien',
  'gynecologue-medical-et-obstetrique',
  'gynecologue-medical',
  'osteopathe',
  'sage-femme',
  'pediatre',
  'masseur-kinesitherapeute',
  'vaccinateur',
  'oncologue',
  'onco-hematologue',
  'onco-radiotherapeute',
  'oncologue-medical',
  'ophtalmologue',
  'pharmacien',
  'podo-orthesiste',
];

export const languagesList = [
  'fr',
  'pt',
  'de',
  'en',
  'ar',
  'bn',
  'zh',
  'da',
  'es',
  'hi',
  'hu',
  'it',
  'jp',
  'nl',
  'no',
  'pl',
  'ro',
  'ru',
  'sv',
  'tr',
  'id',
  'ko',
  'vi',
  'bg',
  'hy',
  'he',
  'ht',
  'rc',
  'el',
];

export const LOCALSTORAGE_KEYS = {
  AMC_USER_DATA: 'AMCUserData',
};

export const SESSIONSTORAGE_KEYS = {
  SSO_USER_DATA: 'SSOUserData',
  LINK_ACCOUNT: 'LinkAccount',
};

// TODO : Big refacto needed, put all the routes used through the app in this
//  object so that if one changes, it's applied everywhere
export const APP_ROUTES = {
  HOME: '/',
  RDV: '/rdv',
  LOGIN: '/login',
  CONTACT: '/contact',
  REGISTER: '/register',
  DOCUMENTS: '/documents',
  FORGOT_PASSWORD: '/forgot-password',
  PROFILE_CREATION: '/profile-creation',
  SMS_CONFIRMATION: '/sms-confirmation',
  VACCINES_NEAR_ME: '/vaccins-pres-de-chez-moi',
  APPOINTMENT_CONFIRMATION: '/appointment-confirmation',
  PHARMACIES_NEAR_ME: '/pharmacies-pres-de-chez-moi',
  APPOINTMENT_LITE: '/appointment-lite',
  ACCOUNT: {
    PROFILE: '/account/profile',
    CHANGE_EMAIL: '/account/profile/change-email',
    CHANGE_SIGNATURE: '/account/profile/change-signature',
    ADD_INSURANCE: '/account/profile/add-insurance',
    CHANGE_PASSWORD: '/account/profile/change-password',
    NEW_PROFILE_PATIENT: '/account/profile/patient-profiles/new',
    EDIT_PROFILE_PATIENT: '/account/profile/patient-profiles/edit',
    SSO_PROFILE_PATIENT: '/account/profile/patient-profiles/sso',
    CHANGE_PHONE: '/account/profile/change-phone',
    PAYMENT_METHODS: '/account/profile/payment-methods',
    ADD_PAYMENT_METHOD: '/account/profile/add-payment-method',
    INSURANCES: '/account/profile/insurances',
    RELATIVES: '/account/profile/relatives',
  },
  WELCOME_TERMINAL: {
    CENTER_ID: '/accueil/[centerId]',
  },
  TLC: {
    BASE_URL: '/teleconsultation',
    SESSION_ID: '/tlc/session/[id]',
    TLC: '/tlc/[center-id]/[practitioner-id]',
    NO_RDV_SEARCH: '/teleconsultation/sans-rdv/recherche',
    NO_RDV_SEARCH_SLUG: '/teleconsultation/sans-rdv/recherche/[[...slug]]',
    AMC: '/teleconsultation/sans-rdv/amc',
  },
  SPECIALITY: {
    LOCALITY: '/[speciality]/[locality]',
    LOCALITY_SLUG: '/[speciality]/[locality]/[slug]',
    LOCALITY_SLUG_RDV: '/[speciality]/[locality]/[slug]/rdv',
    ARTICLE: '/[speciality]/article/[[...article-slugs]]',
    PRACTITIONERS_INDEX: '/[speciality]/index/1',
  },
  SEARCH: {
    SUGGESTION: '/search/suggestions',
  },
  APPOINTMENT: {
    ID: '/appointment/[id]',
    PRECALL: '/appointment/[id]/precall',
  },
  ANONYMOUS: {
    ID: '/anonymous/[id]',
    ID_LOGIN: '/anonymous/[id]/login',
  },
  POLICIES: {
    CGUS: '/mentions-legales',
    PRIVACY: '/donnees-personnelles',
    SEO: '/charte-de-referencement',
    COVID: '/mention-information-rgpd-vaccination-covid',
  },
  FAQ: {
    BASE_URL: '/faq',
    TLC: '/faq-tlc',
    RDV: '/faq-rdv',
  },
  LINK_ACCOUNT: {
    VERIFY: '/link-account/verify',
  },
  EXPERTISES: {
    BASE_URL: '/expertises',
    INDEX: '/expertises/index',
  },
  PHARMACIES: {
    BASE_URL: '/pharmacies',
    INDEX: '/pharmacies/index/1',
  },
  PRACTITIONERS: {
    BASE_URL: '/practitioners',
    INDEX: '/practitioners/index/1',
  },
  SPECIALITIES: {
    BASE_URL: '/specialities',
    INDEX: '/specialities/index',
  },
  MEDICAMENTS: {
    BASE_URL: '/medicaments-claude-bernard',
    INDEX: '/medicaments-claude-bernard/index/1',
    SLUG: '/medicaments-claude-bernard/[...slugs]',
    SUGGESTIONS: '/medicaments-claude-bernard/suggestions',
  },
  SSO: {
    CONFIRM: '/sso/confirm',
  },
  LOGIN_CONFIRMATION: '/login-confirmation',
  WAITING_PROPOSALS: '/waiting-proposals',
  ABOUT_US: 'https://www.cegedim-sante.com/cegedim-sante/',
  ASSISTED_TLC: '/assisted-tlc',
};

export const TLC_UPLOADING_DOCUMENT = 'TLC_UPLOADING_DOCUMENT';

export const MAP_PHARMARCIES_URL =
  'https://www.google.com/maps/d/embed?mid=1POVJkLfIGnNqGEMUKhO2Ru5Im_Z-UC9m&ll=46.6235287212692%2C1.4064084902730656&z=6';

export const ACCEPT_FILE_PATTERN = 'image/*,application/pdf';

export const POLICIES_ORGANISATIONS_COORDONNEES_TERRITORIALES =
  'https://www.ameli.fr/medecin/exercice-liberal/telemedecine/teleconsultation/organisations-coordonnees-territoriales';
export const SSO_DEFAULT_REDIRECT_TARGET = APP_ROUTES.TLC.NO_RDV_SEARCH;

const ONE_HOUR_IN_MILISECONDS = 60 * 60 * 1000;

export const SSO_DATA_EXP_TIME = 0.5 * ONE_HOUR_IN_MILISECONDS;

export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';
export const SUNDAY = 'SUNDAY';
export const CENTER = 'CENTER';
export const PRACTITIONER = 'PRACTITIONER';
export const NONE = 'NONE';
export const CANCELLED = 'CANCELLED';
export const PHYSICAL = 'PHYSICAL';
export const TELECONSULTATION = 'TELECONSULTATION';
export const DIRECT_TELECONSULTATION = 'DIRECT_TELECONSULTATION';
export const OTHER = 'OTHER';
export const PHARMACY = 'PHARMACY';
export const PHYSICAL_OR_TELECONSULTATION = 'PHYSICAL_OR_TELECONSULTATION';
export const MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE';
export const BIOLOGICAL_ANALYSIS = 'BIOLOGICAL_ANALYSIS';
export const REPORT = 'REPORT';
export const MEDICAL_IMAGING = 'MEDICAL_IMAGING';
export const PREVENTION = 'PREVENTION';
export const PRESCRIPTION = 'PRESCRIPTION';
export const CONSULTATION_REASON = 'CONSULTATION_REASON';
export const DONE = 'DONE';
export const FINISHED = 'FINISHED';
export const TLC_24_7 = 'TLC_24_7';
export const HOME_VISIT = 'HOME_VISIT';
export const TLC_RELOAD_COUNT = 'TLC_RELOAD_COUNT';

export const documentLabelCategories = [
  MEDICAL_CERTIFICATE,
  BIOLOGICAL_ANALYSIS,
  REPORT,
  MEDICAL_IMAGING,
  PREVENTION,
  PRESCRIPTION,
  OTHER,
];

export const ZENDESK_BASE_URL = 'https://maiia-guide.zendesk.com/hc/fr/';

export const NUMBER_OF_DAYS_TO_SHOW_WAITING_LIST_TOGGLE = 3;

export const PICK_VIDEO_SESSION_SESSION_STORAGE_KEY =
  'pickUpCurrentVideoSession';

export const AVATAR_HEX_COLORS = [
  { text: '#7C1B55FF', background: '#fde2f2' },
  { text: '#00581EFF', background: '#e1f9e9' },
  { text: '#1B5C7CFF', background: '#e2f4fd' },
  { text: '#1B2B7CFF', background: '#e2e7fd' },
  { text: '#8E0606FF', background: '#fde2e2' },
  { text: '#8E4C00FF', background: '#fbe5cb' },
];

export const AVATAR_COLORS = [
  { background: 'bg-picker-light-pink', text: 'text-picker-pink' },
  { background: 'bg-picker-light-green', text: 'text-picker-green' },
  { background: 'bg-picker-light-blue', text: 'text-picker-blue' },
  { background: 'bg-picker-light-indigo', text: 'text-picker-indigo' },
  { background: 'bg-picker-light-red', text: 'text-picker-red' },
  { background: 'bg-picker-light-orange', text: 'text-picker-orange' },
];
